<tab-header-issue class="gitlab-header" [workPackage]="workPackage"></tab-header-issue>
<tab-issue [workPackage]="workPackage"></tab-issue>
<tab-header-mr class="gitlab-header" [workPackage]="workPackage"></tab-header-mr>
<tab-mrs [workPackage]="workPackage"></tab-mrs>

<script>
document.addEventListener('DOMContentLoaded', function () {
  // Your Intersection Observer code and any other JavaScript/TypeScript logic
  const sectionHeaders = document.querySelectorAll('gitlab-header');

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      const sectionHeader = entry.target;

      if (entry.isIntersecting) {
        sectionHeader.classList.add('sticky');
      } else {
        sectionHeader.classList.remove('sticky');
      }
    });
  }, { threshold: 1 });

  sectionHeaders.forEach(sectionHeader => {
    observer.observe(sectionHeader);
  });

  const initialIssueLabelsElements = document.querySelectorAll('.op-issue--labels');
  initialIssueLabelsElements.forEach(function (element) {
    element.style.display = 'none';
  });

  const initialMRLabelsElements = document.querySelectorAll('.op-merge-request--labels');
  initialMRLabelsElements.forEach(function (element) {
    element.style.display = 'none';
  });
});
</script>

<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <symbol viewBox="0 0 16 16" id="issue_open" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 2.5h6a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5zM1 3a2 2 0 0 1 2-2h6a2 2 0 0 1 1.97 1.658l2.913 1.516a1.75 1.75 0 0 1 .744 2.36l-3.878 7.45a.753.753 0 0 1-.098.145c-.36.526-.965.871-1.651.871H3a2 2 0 0 1-2-2V3zm10 7.254l2.297-4.413a.25.25 0 0 0-.106-.337L11 4.364v5.89z"/>
  </symbol>
  <symbol viewBox="0 0 16 16" id="issue_closed" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 2.5H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5zM3 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h6a2 2 0 0 0 1.651-.87.753.753 0 0 0 .098-.145l3.878-7.45a1.75 1.75 0 0 0-.744-2.361l-2.912-1.516A2 2 0 0 0 9 1H3zm10.297 4.841L11 10.254v-5.89l2.19 1.14a.25.25 0 0 1 .107.337zM8.28 7.281A.75.75 0 0 0 7.22 6.22L5.25 8.19l-.47-.47a.75.75 0 0 0-1.06 1.06l1 1a.75.75 0 0 0 1.06 0l2.5-2.5z"/>
  </symbol>
  <symbol viewBox="0 0 16 16" id="merge-request_open" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.34 1.22a.75.75 0 0 0-1.06 0L7.53 2.97 7 3.5l.53.53 1.75 1.75a.75.75 0 1 0 1.06-1.06l-.47-.47h.63c.69 0 1.25.56 1.25 1.25v4.614a2.501 2.501 0 1 0 1.5 0V5.5a2.75 2.75 0 0 0-2.75-2.75h-.63l.47-.47a.75.75 0 0 0 0-1.06zM13.5 12.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-9 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm1.5 0a2.5 2.5 0 1 1-3.25-2.386V5.886a2.501 2.501 0 1 1 1.5 0v4.228A2.501 2.501 0 0 1 6 12.5zm-1.5-9a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
  </symbol>
  <symbol viewBox="0 0 16 16" id="merge-request_ready" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.34 1.22a.75.75 0 0 0-1.06 0L7.53 2.97 7 3.5l.53.53 1.75 1.75a.75.75 0 1 0 1.06-1.06l-.47-.47h.63c.69 0 1.25.56 1.25 1.25v4.614a2.501 2.501 0 1 0 1.5 0V5.5a2.75 2.75 0 0 0-2.75-2.75h-.63l.47-.47a.75.75 0 0 0 0-1.06zM13.5 12.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-9 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm1.5 0a2.5 2.5 0 1 1-3.25-2.386V5.886a2.501 2.501 0 1 1 1.5 0v4.228A2.501 2.501 0 0 1 6 12.5zm-1.5-9a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
  </symbol>
  <symbol viewBox="0 0 16 16" id="merge-request_closed" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.22 1.22a.75.75 0 0 1 1.06 0L3.5 2.44l1.22-1.22a.75.75 0 0 1 1.06 1.06L4.56 3.5l1.22 1.22a.75.75 0 0 1-1.06 1.06L3.5 4.56 2.28 5.78a.75.75 0 0 1-1.06-1.06L2.44 3.5 1.22 2.28a.75.75 0 0 1 0-1.06zM7.5 3.5a.75.75 0 0 1 .75-.75h2.25a2.75 2.75 0 0 1 2.75 2.75v4.614a2.501 2.501 0 1 1-1.5 0V5.5c0-.69-.56-1.25-1.25-1.25H8.25a.75.75 0 0 1-.75-.75zm5 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-8-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm1.5 0a2.5 2.5 0 1 1-3.25-2.386V7.75a.75.75 0 0 1 1.5 0v2.364A2.501 2.501 0 0 1 6 12.5z"/>
  </symbol>
  <symbol viewBox="0 0 16 16" id="merge-request_merged" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 3.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-.044 2.31a2.5 2.5 0 1 0-1.706.076v4.228a2.501 2.501 0 1 0 1.5 0V8.373a5.735 5.735 0 0 0 3.86 1.864 2.501 2.501 0 1 0 .01-1.504 4.254 4.254 0 0 1-3.664-2.922zM11.5 10.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-6 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
  </symbol>
  <symbol viewBox="0 0 16 16" id="labels" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.427.512A1.75 1.75 0 0 1 7.664 0H13v3h3v5.335c0 .465-.185.91-.513 1.239L9.573 15.48a1.75 1.75 0 0 1-2.473 0l-2.293-2.293-1.293-1.293-3-3a1.75 1.75 0 0 1 0-2.475L6.428.512zM11.5 1.5V3h-.836a1.75 1.75 0 0 0-1.237.512L3.514 9.419c-.06.06-.115.123-.165.19L1.574 7.833a.25.25 0 0 1 0-.353l5.913-5.907a.25.25 0 0 1 .177-.073H11.5zM5.866 12.126l-1.292-1.293a.25.25 0 0 1 0-.353l5.913-5.907a.25.25 0 0 1 .177-.073H14.5v3.835a.25.25 0 0 1-.073.177L8.513 14.42a.25.25 0 0 1-.353 0l-2.294-2.293zM12 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
  </symbol>
  <symbol viewBox="0 0 14 14" id="pipeline-status-success" xmlns="http://www.w3.org/2000/svg">
    <g fill-rule="evenodd">
      <path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z"/>
      <path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="var(--svg-status-bg, #fff)"/>
      <path d="M6.278 7.697L5.045 6.464a.296.296 0 0 0-.42-.002l-.613.614a.298.298 0 0 0 .002.42l1.91 1.909a.5.5 0 0 0 .703.005l.265-.265L9.997 6.04a.291.291 0 0 0-.009-.408l-.614-.614a.29.29 0 0 0-.408-.009L6.278 7.697z"/>
    </g>
  </symbol>
  <symbol viewBox="0 0 14 14" id="pipeline-status-failed" xmlns="http://www.w3.org/2000/svg">
    <g fill-rule="evenodd">
      <path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z"/>
      <path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="var(--svg-status-bg, #fff)"/>
      <path d="M7 5.969L5.599 4.568a.29.29 0 0 0-.413.004l-.614.614a.294.294 0 0 0-.004.413L5.968 7l-1.4 1.401a.29.29 0 0 0 .004.413l.614.614c.113.114.3.117.413.004L7 8.032l1.401 1.4a.29.29 0 0 0 .413-.004l.614-.614a.294.294 0 0 0 .004-.413L8.032 7l1.4-1.401a.29.29 0 0 0-.004-.413l-.614-.614a.294.294 0 0 0-.413-.004L7 5.968z"/>
    </g>
  </symbol>
  <symbol viewBox="0 0 14 14" id="pipeline-status-running" xmlns="http://www.w3.org/2000/svg">
    <g fill-rule="evenodd">
        <path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z"/>
        <path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="var(--svg-status-bg, #fff)"/>
        <path d="M7 3c2.2 0 4 1.8 4 4s-1.8 4-4 4c-1.3 0-2.5-.7-3.3-1.7L7 7V3"/>
    </g>
  </symbol>
  <symbol viewBox="0 0 14 14" id="pipeline-status-created" xmlns="http://www.w3.org/2000/svg">
    <g fill-rule="evenodd">
        <path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z"/>
        <path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="var(--svg-status-bg, #fff)"/>
        <circle cx="7" cy="7" r="3.25"/>
    </g>
  </symbol>
  <symbol viewBox="0 0 12 12" id="pipeline-status-waiting" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 10a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0 2A6 6 0 1 0 6 0a6 6 0 0 0 0 12z"/>
  </symbol>
  <symbol viewBox="0 0 14 14" id="pipeline-status-preparing" xmlns="http://www.w3.org/2000/svg">
    <g fill-rule="evenodd">
        <g fill-rule="nonzero">
            <path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z"/>
            <path fill="var(--svg-status-bg, #fff)" d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z"/>
        </g>
        <circle cx="7" cy="7" r="1"/>
        <circle cx="10" cy="7" r="1"/>
        <circle cx="4" cy="7" r="1"/>
    </g>
  </symbol>
  <symbol viewBox="0 0 14 14" id="pipeline-status-pending" xmlns="http://www.w3.org/2000/svg">
    <g fill-rule="evenodd">
        <path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z"/>
        <path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="var(--svg-status-bg, #fff)"/>
        <path d="M4.7 5.3c0-.2.1-.3.3-.3h.9c.2 0 .3.1.3.3v3.4c0 .2-.1.3-.3.3H5c-.2 0-.3-.1-.3-.3V5.3m3 0c0-.2.1-.3.3-.3h.9c.2 0 .3.1.3.3v3.4c0 .2-.1.3-.3.3H8c-.2 0-.3-.1-.3-.3V5.3"/>
    </g>
  </symbol>
  <symbol viewBox="0 0 14 14" id="pipeline-status-canceled" xmlns="http://www.w3.org/2000/svg">
    <g fill-rule="evenodd">
        <path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z"/>
        <path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="var(--svg-status-bg, #fff)"/>
        <path d="M5.2 3.8l4.9 4.9c.2.2.2.5 0 .7l-.7.7c-.2.2-.5.2-.7 0L3.8 5.2c-.2-.2-.2-.5 0-.7l.7-.7c.2-.2.5-.2.7 0"/>
    </g>
  </symbol>
  <symbol viewBox="0 0 14 14" id="pipeline-status-skipped" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 14A7 7 0 1 1 7 0a7 7 0 0 1 0 14z"/>
    <path d="M7 13A6 6 0 1 0 7 1a6 6 0 0 0 0 12z" fill="var(--svg-status-bg, #fff)"/>
    <path d="M6.415 7.04L4.579 5.203a.295.295 0 0 1 .004-.416l.349-.349a.29.29 0 0 1 .416-.004l2.214 2.214a.289.289 0 0 1 .019.021l.132.133c.11.11.108.291 0 .398L5.341 9.573a.282.282 0 0 1-.398 0l-.331-.331a.285.285 0 0 1 0-.399L6.415 7.04zm2.54 0L7.119 5.203a.295.295 0 0 1 .004-.416l.349-.349a.29.29 0 0 1 .416-.004l2.214 2.214a.289.289 0 0 1 .019.021l.132.133c.11.11.108.291 0 .398L7.881 9.573a.282.282 0 0 1-.398 0l-.331-.331a.285.285 0 0 1 0-.399L8.955 7.04z"/>
  </symbol>
  <symbol viewBox="0 0 14 14" id="pipeline-status-manual" xmlns="http://www.w3.org/2000/svg">
    <g fill-rule="evenodd">
        <path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z"/>
        <path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="var(--svg-status-bg, #fff)"/>
        <path d="M10.5 7.63V6.37l-.787-.13c-.044-.175-.132-.349-.263-.61l.481-.652-.918-.913-.657.478a2.346 2.346 0 0 0-.612-.26L7.656 3.5H6.388l-.132.783c-.219.043-.394.13-.612.26l-.657-.478-.918.913.437.652c-.131.218-.175.392-.262.61l-.744.086v1.261l.787.13c.044.218.132.392.263.61l-.438.651.92.913.655-.434c.175.086.394.173.613.26l.131.783h1.313l.131-.783c.219-.043.394-.13.613-.26l.656.478.918-.913-.48-.652c.13-.218.218-.435.262-.61l.656-.13zM7 8.283a1.285 1.285 0 0 1-1.313-1.305c0-.739.57-1.304 1.313-1.304.744 0 1.313.565 1.313 1.304 0 .74-.57 1.305-1.313 1.305z"/>
    </g>
  </symbol>
  <symbol viewBox="0 0 14 14" id="pipeline-status-scheduled" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 14A7 7 0 1 0 7 0a7 7 0 0 0 0 14z"/>
    <path d="M7 13A6 6 0 1 0 7 1a6 6 0 0 0 0 12z" fill="#fff"/>
    <path d="M6.995 10.64a3.645 3.645 0 1 1 0-7.29 3.645 3.645 0 0 1 0 7.29zm0-1.042a2.603 2.603 0 1 0 0-5.206 2.603 2.603 0 0 0 0 5.206z"/>
    <path d="M7.033 4.92h-.065a.488.488 0 0 0-.488.488v1.627c0 .27.218.488.488.488h.065c.27 0 .488-.218.488-.488V5.408a.488.488 0 0 0-.488-.488z"/>
    <path d="M8.075 6.48H6.968a.488.488 0 0 0-.488.488v.065c0 .27.218.488.488.488h1.107c.27 0 .488-.218.488-.488v-.065a.488.488 0 0 0-.488-.488z"/>
  </symbol>
</svg>
