<div class="op-draggable-autocomplete">
  <div class="FormControl-spacingWrapper mb-3">
    <div class="FormControl width-full" id="op-draggable-autocomplete-container">
      <label class="FormControl-label"
             [textContent]="inputLabel"
             [attr.for]="id">
      </label>

      <ng-select
        #ngSelectComponent
        [items]="availableOptions"
        ngClass="op-draggable-autocomplete--input ng-select--primerized"
        bindLabel="name"
        [multiple]="false"
        [virtualScroll]="true"
        appendTo="body"
        [placeholder]="inputPlaceholder"
        [clearSearchOnAdd]="true"
        [closeOnSelect]="true"
        [searchFn]="searchFunction"
        [appendTo]="appendTo"
        (open)="opened()"
        (change)="select($event)"
      ></ng-select>
    </div>
  </div>

  <div class="FormControl-spacingWrapper">
    <div class="FormControl width-full">
      <label class="FormControl-label"
             [textContent]="dragAreaLabel">
      </label>

      <div
        class="op-draggable-autocomplete--selected"
        dragula="columns"
        [(dragulaModel)]="selectedOptions"
      >
        <div class="op-draggable-autocomplete--item" *ngFor="let item of selectedOptions">
          <span
            class="op-draggable-autocomplete--item-text"
            [textContent]="item.name"
          ></span>
          <a
            *ngIf="isRemovable(item)"
            (click)="remove(item)"
            class="op-draggable-autocomplete--remove-item icon-remove"
          ></a>
        </div>

        <span *ngIf="!isArrayOfValues">
          <input type="hidden" [name]="name" [value]="hiddenValue" />
        </span>
        <span *ngIf="isArrayOfValues">
          <input *ngFor="let value of hiddenValues" type="hidden" [name]="name" [value]="value" />
        </span>
      </div>
    </div>
  </div>
</div>
